import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  Inject,
  input,
  InputSignal,
  Optional,
  output,
  OutputEmitterRef,
  Self,
} from '@angular/core';
import { WLCM_FORM_CONTROL, WLCM_FORM_CONTROL_PROVIDER, WlcmFormControl, WlcmFormsModule } from '@wlcm/angular/forms';
import { InputWithUnitComponent } from '@shared/components/forms/input-with-unit/input-with-unit.component';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WlcmIconDirective, WlcmIconName, WlcmOption } from '@wlcm/angular/core';
import { FamilyMemberForm } from '../../models/family-member-form.models';
import { WlcmPhoneInputModule } from '@wlcm/angular/phone-input';
import { WlcmDatepickerModule } from '@wlcm/angular/datepicker';
import { HeightOptions, WeightOptions } from '@core/constants';
import { KidFormModel, KidFormValue } from '../../models/kid-form.models';
import { Child, ChildPayload } from '@core/models/entities/child.models';
import { MatSelectChange } from '@angular/material/select';
import { patchSelectableKidForm } from '../../utils';

@Component({
  selector: 'napa-kid-form',
  standalone: true,
  imports: [
    WlcmFormsModule,
    WlcmPhoneInputModule,
    ReactiveFormsModule,
    InputWithUnitComponent,
    WlcmDatepickerModule,
    WlcmIconDirective,
  ],
  templateUrl: './kid-form.component.html',
  styleUrl: './kid-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WLCM_FORM_CONTROL_PROVIDER],
})
export class KidFormComponent extends FamilyMemberForm<KidFormModel, ChildPayload> {
  readonly WlcmIconName = WlcmIconName;

  readonly heightOptions: WlcmOption[] = HeightOptions;

  readonly weightOptions: WlcmOption[] = WeightOptions;

  kidOptions: InputSignal<WlcmOption[]> = input<WlcmOption[]>([]);

  selected: OutputEmitterRef<Child> = output();

  maxDate: Date = new Date();

  constructor(
    protected override changeDetector: ChangeDetectorRef,
    @Inject(WLCM_FORM_CONTROL) protected override wlcmFormControl: WlcmFormControl,
    @Optional() @Host() @Self() protected override controlContainer: ControlContainer
  ) {
    super(changeDetector, wlcmFormControl, controlContainer);
  }

  get includesDiagnosis(): boolean {
    return this.form.contains('diagnosis');
  }

  get payload(): ChildPayload {
    const formValue = this.form.getRawValue();

    const payload: Partial<ChildPayload> = { ...formValue };

    if (formValue.full_name instanceof Object) {
      payload.full_name = formValue.full_name.value;
    }

    return payload as ChildPayload;
  }

  override get storePayload(): KidFormValue {
    return this.form.getRawValue();
  }

  get isValid(): boolean {
    return this.form.valid;
  }

  handleSelectionChange(event: MatSelectChange): void {
    const child: Child = event.value.data;

    patchSelectableKidForm(this.form, child, false);
  }

  markAllAsTouched(): void {
    this.form.markAllAsTouched();

    this.changeDetector.markForCheck();
  }

  validateControlContainer(controlContainer: ControlContainer): void {
    if (!controlContainer || !(controlContainer?.control instanceof FormGroup)) {
      throw new Error('FormGroup<KidFormModel> is not provided.');
    }
  }
}
